/* BlogPosts.css */

/* Container styles */
.blog-posts-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Title styles */
.blog-title {
  font-size: 2em;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

/* Introduction styles */
.blog-introduction {
  font-size: 1.2em;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
}

/* Additional styles for DisplayBlogPosts component */
/* You can create a separate CSS file for DisplayBlogPosts component if needed */
/* For demonstration, we'll include a simple style here */
.display-blog-posts {
  margin-top: 20px;
}
